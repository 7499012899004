import queryString from 'query-string';
import { FirebaseApp } from 'firebase/app';
import {
  getAuth,
  Auth as FirebaseAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithPopup,
  UserCredential,
  connectAuthEmulator,
} from 'firebase/auth';
import Cookies from 'universal-cookie';
import { Constants } from 'src/constants';
import { createError } from '@moonsifttech/design-system';

import { Mobile, OutgoingMessageType } from 'src/services/Mobile';
import { Cloud } from 'src/services/Cloud';
import { M } from 'src/mvp22/constants';
import { Analytics } from 'src/services/Analytics';

const cookies = new Cookies();

function getNameFromUserCredential(user: User): string {
  const displayName = user.displayName;

  if (displayName) {
    return displayName.substring(0, 50);
  }

  const email = user.email;

  if (email) {
    const [emailUsername] = email.split('@');
    return emailUsername.substring(0, 50);
  }

  const now = Date.now();
  return `user.${now}`;
}

export class Auth {
  auth: FirebaseAuth;
  facebook: FacebookAuthProvider;
  google: GoogleAuthProvider;
  apple: OAuthProvider;
  signInToken: string | undefined;

  constructor(
    app: FirebaseApp,
    private mobile: Mobile,
    private cloud: Cloud,
    private analytics: Analytics,
  ) {
    const auth = getAuth(app);
    const appleProvider = new OAuthProvider('apple.com');
    appleProvider.addScope('email');
    appleProvider.addScope('name');

    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
    googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

    const signInToken = queryString.parse(
      window.location.search,
    ).test_sign_in_token;
    if (typeof signInToken === 'string') {
      this.signInToken = signInToken;
    }
    const enableAISearch = queryString.parse(window.location.search).ai_search;
    if (typeof enableAISearch === 'string') {
      cookies.set(M.HAS_AI_SEARCH, 'true', {
        maxAge: 60 * 60 * 24 * 90,
        path: M.COOKIEPATH,
      });
    }
    this.auth = auth;
    this.facebook = new FacebookAuthProvider();
    this.google = googleProvider;
    this.apple = appleProvider;
    this.analytics = analytics;

    if (process.env.REACT_APP_USE_LOCAL_AUTH === 'TRUE') {
      connectAuthEmulator(auth, 'http://localhost:9099');
    }

    const onMessage = async (event: MessageEvent<any>) => {
      if (event.data === 'moonsift_chrome_extension_get_token') {
        const token = await this.getCustomToken();
        if (token) {
          chrome.runtime.sendMessage(Constants.EXTENSION_ID, {
            action: 'auth-login',
            token,
            project: process.env.REACT_APP_FIREBASE_PROJECTID,
          });
        }
      }
    };
    window.addEventListener('message', onMessage);
  }

  async getCustomToken() {
    if (this.auth.currentUser?.uid) {
      try {
        const response = await this.cloud
          .fastAPI({
            api: 'users_create_custom_token',
          })
          .then((result) => result as { data: string });
        return response.data;
      } catch {
        // Treat any failure as signed out:
        return null;
      }
    }
    return null;
  }

  // Functions:
  async provisionAccount(name: string) {
    const referredBy = cookies.get(M.REFERRAL_COOKIE_KEY) || null;
    const procode = cookies.get(M.PRO_CODE_COOKIE_KEY) || null;
    const hasAISearch = cookies.get(M.HAS_AI_SEARCH) || null;
    const referrer = document.referrer;
    const landing = cookies.get(Constants.LANDINGPAGE_COOKIE_KEY) || null;
    const is_app = this.mobile.isApp;

    this.analytics.recordEvent('WebApp:ProvisionNewAccount', { name });
    const { data } = await this.cloud.fastAPI({
      api: 'users_provision_account',
      referred_by: referredBy,
      procode,
      name,
      referrer,
      landing,
      is_app,
      enable_ai_search: hasAISearch === 'true',
    });
    const typedData = data as any;

    if (typedData.provision_success === true) {
      cookies.set(M.PRO_CODE_COOKIE_KEY, '', {
        maxAge: 60 * 60 * 24 * 90,
        path: M.COOKIEPATH,
      });

      return data;
    }

    throw createError('CloudError', 'Failed to provision!');
  }
  signInWithEmailAndPassword = (email: string, password: string) =>
    signInWithEmailAndPassword(this.auth, email, password);
  legacyCreateUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(this.auth, email, password);
  async createUserWithEmailAndPassword(
    name: string,
    email: string,
    password: string,
  ): Promise<UserCredential> {
    const userCredential = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password,
    );
    await this.provisionAccount(name);
    return userCredential;
  }
  signOut = () => signOut(this.auth);
  sendPasswordResetEmail = (email: string) =>
    sendPasswordResetEmail(this.auth, email);
  signInWithCustomToken = (token: string) =>
    signInWithCustomToken(this.auth, token);
  signInWithProvider = (providerName: 'facebook' | 'google' | 'apple') => {
    const authProvider = this[providerName];
    return signInWithPopup(this.auth, authProvider);
  };
  async provisionWithCurrentCredentials() {
    const user = this.auth.currentUser;
    if (user) {
      const name = getNameFromUserCredential(user);
      await this.provisionAccount(name);
    }
    return user;
  }

  logoutApp(): void | never {
    this.mobile.postMessage(OutgoingMessageType.Logout);
  }

  logoutExtension() {
    if (typeof chrome !== 'undefined' && chrome.runtime) {
      chrome.runtime.sendMessage(Constants.EXTENSION_ID, {
        action: 'auth-logout',
        project: process.env.REACT_APP_FIREBASE_PROJECTID,
      });
    }
  }

  tellExtensionWeAreLoggedIn() {
    if (
      typeof chrome !== 'undefined' &&
      chrome.runtime &&
      this.auth?.currentUser?.uid
    ) {
      chrome.runtime.sendMessage(Constants.EXTENSION_ID, {
        action: 'auth-check',
        authUserUID: this.auth.currentUser.uid,
        project: process.env.REACT_APP_FIREBASE_PROJECTID,
      });
    }
  }
}

export type UserObject = User;
