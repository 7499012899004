import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Paper, Stack, Typography } from '@moonsifttech/design-system';

import { RelinkFacebookForm } from 'src/components/auth/RelinkFacebookForm';

const UnstyledRelinkFacebook = ({
  className,
  style
}: {
  className?: string;
  style: React.CSSProperties;
}) => {
  return (
    <Stack
      className={className}
      style={style}
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Join us - Moonsift</title>
      </Helmet>
      <Paper className="RelinkFacebook-paper">
        <Stack
          className="RelinkFacebook-container"
          direction="column"
          alignItems="center"
        >
          <Stack className="RelinkFacebook-logo" direction="row">
            <img
              className="RelinkFacebook-logoImage"
              src={`${process.env.REACT_APP_STATIC_URL}/logos/logo.svg`}
              alt="Monsift logo"
            />
            <Typography className="RelinkFacebook-logoText" variant="branded.b42">
              MOONSIFT
            </Typography>
          </Stack>
          <Stack justifyContent='center' className="RelinkFacebook-explainerContainer">
            <Typography
              variant="primary.b16"
              align='center'
              style={{ marginBottom: '1rem' }}
            >
              Facebook login can no longer be supported.
            </Typography>
            <Typography
              variant="primary.r16"
              align='center'
              style={{ marginBottom: '1rem' }}
            >
              Don't worry, your profile and everything you've saved to Moonsift is still here.
            </Typography>
            <Typography
              variant="primary.r16"
              align='center'
              style={{ marginBottom: '1.5rem' }}
            >
              Simply choose an email and password to log into your account going forward.
            </Typography>
          </Stack>
          <RelinkFacebookForm />
        </Stack>
      </Paper>
    </Stack>
  );
};

export const RelinkFacebook = styled(UnstyledRelinkFacebook)`
  width: 100%;
  height: 100vh;
  background: var(--grey-1);

  .RelinkFacebook-paper {
    padding: 36px 14px;
    width: 480px;
  }

  .RelinkFacebook-logo {
    margin-bottom: 36px;
    align-items: center;

    .RelinkFacebook-logoImage {
      height: 50px;
    }

    .RelinkFacebook-logoText {
      padding: 8px 0 0 4px;
    }
  }

  .RelinkFacebook-explainerContainer {
    max-width: 320px;
  }
`;
