import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { object, string } from 'yup';
import R from 'src/routes';
import {
  SignUpFormContainerProps,
  SignUpFormProps,
  SignUpFormFormContent,
} from './SignUpForm.types';
import { SignUpForm } from './SignUpForm.component';
import { useSignInProviders } from 'src/hooks/useSignInProviders/useSignInProviders';

const initialValues: SignUpFormFormContent = {
  fullName: '',
  email: '',
  password: '',
};

const validationSchema = object({
  fullName: string()
    .max(50, 'Max 50 characters.')
    .required('Name cannot be blank.'),
  email: string()
    .email('Must be a valid email.')
    .max(254, 'Max 254 characters.')
    .required('Email is required.'),
  password: string()
    .min(6, 'Password is required.')
    .max(128, 'Maximum 128 characters.')
    .required('Password is required.'),
}).required();

const SignUpFormContainer: React.FC<SignUpFormContainerProps> = () => {
  const match = useRouteMatch(R.SIGNUPFROMEXTENSION);
  const isExtensionSignUp = Boolean(match?.isExact);

  const {
    handleUserEmailSignUp,
    handleGoogleAuth,
    handleAppleAuth,
    isWorking,
    mainErrors,
  } = useSignInProviders( isExtensionSignUp ? R.POSTINSTALL_ONE : R.POSTSIGNUP);

  const handleSubmit = useCallback<SignUpFormProps['onSubmit']>(
    ({ fullName, email, password }, actions) => {
      handleUserEmailSignUp({ fullName, email, password });
      actions.setSubmitting(false);
    },
    [handleUserEmailSignUp],
  );

  return (
    <SignUpForm
      signInPath={isExtensionSignUp ? R.SIGNINFROMEXTENSION : R.SIGNIN}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mainErrors={mainErrors}
      isWorking={isWorking}
      onSubmit={handleSubmit}
      onGoogleAuth={handleGoogleAuth}
      onAppleAuth={handleAppleAuth}
    />
  );
};

export { SignUpFormContainer as SignUpForm };
