/*
  Only accessible to the owner.
  Shown when multiple purchasers have bought the same item, so the owner can choose which to unmark.
*/

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { object, array, string } from 'yup';
import { Formik, Form, FormikValues } from 'formik';
import {
  Typography,
  StandardButton,
  Stack,
  ControlledFormGroup,
  FormControlLabel,
  ControlledCheckbox,
} from '@moonsifttech/design-system';
import { UserPublic } from 'src/types/models/userPublic.model';
import { RootState } from 'src/index';
import { useModal } from 'src/hooks/useModal';
import { useUnmarkAsBought } from 'src/hooks/useUnmarkAsBought';
import { useItem } from 'src/hooks/useItem';
import { ModalContent } from 'src/components/core/ModalContent';

const getName = (
  names: { [ key: string ]: UserPublic | null | undefined },
  userId?: string,
  identityName?: string,
): string => {
  return names[userId ?? 'NOT_LOGGED_IN']?.name
    ?? identityName
    ?? 'Someone';
};

export const UnstyledUnmarkAsBoughtOwnerDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ _, closeModal ] = useModal();

  const { privateRegistry } = useItem(itemId);

  const {
    working,
    unmark,
  } = useUnmarkAsBought({
    collectionUID: collectionId,
    collectionUserUID: collectionUserId,
    collectionItemUID: itemId,
    onSuccess: closeModal,
  });

  const submit = ({ ids }: FormikValues) => {
    unmark(ids);
  };

  const purchaserNames = useSelector((state: RootState) => {
    return state.db.user_public.map;
  });

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        <span>Select which sets to un-mark as bought</span>
      </Typography>

      <Typography variant="primary.r16" component="p">
        Check the boxes next to the items you want to un-mark as bought, then scroll to the bottom to confirm. 
      </Typography>

      <Formik
        initialValues={{ ids: [] }}
        validationSchema={object({
          ids: array().of(string()).min(1).required(),
        })}
        onSubmit={submit}
        validateOnChange
        validateOnMount
      >
        {({ errors }) => (
          <Form>
            <div
              className="UnmarkAsBoughtOwnerDialog-contentWrapper"
            >
              <ControlledFormGroup name="ids">
                {
                  (privateRegistry ?? []).map(({
                    id,
                    purchaser_user_uid,
                    purchaser_identity_name,
                    purchased,
                  }) => (
                    <div key={id} className="UnmarkAsBoughtOwnerDialog-purchaseWrapper">
                      <div>
                        <Typography
                          className="UnmarkAsBoughtOwnerDialog-name"
                          variant="primary.b14"
                          component="span">
                          {getName(
                            purchaserNames,
                            purchaser_user_uid,
                            purchaser_identity_name
                          )}
                        </Typography>
                        <Typography
                          component='span'
                          variant='primary.b14'
                          color='background.purple'
                        >
                          {` x ${purchased ?? 1}`}
                        </Typography>
                      </div>
                      <FormControlLabel
                        label="Select to un-mark as bought"
                        labelPlacement="start"
                        value={id}
                        control={<ControlledCheckbox name="ids" />}
                      />
                    </div>
                  )) 
                }
              </ControlledFormGroup>
            </div>

            <Stack direction="row" justifyContent="flex-end">
              <StandardButton
                type="submit"
                loading={working}
                disabled={Object.keys(errors).length > 0}
              >
                Unmark as bought
              </StandardButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

export const UnmarkAsBoughtOwnerDialog = styled(UnstyledUnmarkAsBoughtOwnerDialog)`
  .UnmarkAsBoughtOwnerDialog-contentWrapper {
    margin-bottom: 2.5rem;
  }

  .UnmarkAsBoughtOwnerDialog-purchaseWrapper {
    margin-bottom: 2rem;
  }
`;
