
/*
  Component for wrapping links we want to be affiliate links.
  Takes either:
    - a naked URL for generic targets like retailer landing pages
    - a product object for collection items and precise affiliate tracking
  Product target will be preferred
*/

import { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ServicesContext } from 'src/ServicesContext';
import { RootState } from 'src/index';
import { ProductData } from 'src/types';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';

const skimlinksExclude = [
  'viglink.com',
  'apps.apple.com',
  'warehouse.co.uk',
  'brownsfashion.com',
  'sandro-paris.com',
  'paulsmith.com',
  'brandalley.co.uk',
  'meandem.com',
  'hervia.com',
  'urbanoutfitters.com',
  'smallable.com',
  'rstyle.me',
  'klar.na',
  'amazon.com',
  'amazon.co.uk',
  'amazon.de',
  'amzn.to',
  'apprl.com',
  'rvlv.me',
  'shopstyle.it',
  'shopmy.us',
  'magik.ly',
  'shrsl.com',
  'bit.ly',
  'lego.com',
  'ounass.ae',
  'jewlr.com',
];

type CollectionItem = {
  collectionUserId: string;
  collectionId: string;
  itemId: string;
}

type BaseProps = {
  children: React.ReactNode;
  target?: '_self' | '_blank' | '_parent' | '_top';
  className?: string;
}
type WithCollectionItem = BaseProps & {
  collectionItem: CollectionItem;
  href?: never;
}
type WithHref = BaseProps & {
  href: string;
  collectionItem?: never;
}

type Props = WithCollectionItem | WithHref;

const Link = styled.a`
  color: inherit;
  text-decoration: inherit;
`;

export const AffiliateLink = (props: Props) => {
  const { cloud } = useContext(ServicesContext);

  const productDataStoreAs = props.collectionItem
    ? getCollectionItemStoreAs(
      props.collectionItem.collectionId,
      props.collectionItem.collectionUserId,
      props.collectionItem.itemId,
    )
    : '';

  const product = useSelector<RootState, ProductData | undefined>(
    ({ productData }) => productData?.[productDataStoreAs]
  );

  let affiliateUrl = '';

  if (props.collectionItem) {
    const productUrl = product?.url;

    if (productUrl) {
      try {
        const parsed = new URL(productUrl);

        if (parsed.hostname.endsWith('amazon.co.uk')) {
          if (!parsed.searchParams.has('tag')) {
            parsed.searchParams.set('tag', 'moonsift03-21');
          }
          affiliateUrl = parsed.href;

        } else if (
          parsed.hostname.includes('.amazon.')
          || parsed.hostname.endsWith('a.co')
          || parsed.hostname.startsWith('amazon.')
        ) {
          affiliateUrl = parsed.href;

        } else if (
          product?.commissionType === 'sovrn'
        ) {
          const wrappedUrl = new URL('http://redirect.viglink.com');
          wrappedUrl.searchParams.set('u', productUrl);
          wrappedUrl.searchParams.set('key', process.env.REACT_APP_SOVRN_API_KEY as string);
          wrappedUrl.searchParams.set('utm_content', `${props.collectionItem.collectionUserId}_${props.collectionItem.collectionId}_${props.collectionItem.itemId}`);
          affiliateUrl = wrappedUrl.href;

        } else {
          if (!skimlinksExclude.includes(parsed.hostname)) {
            const wrappedUrl = new URL('https://go.skimresources.com');
            wrappedUrl.searchParams.set('id', '126855X1588436');
            wrappedUrl.searchParams.set('url', productUrl);
            wrappedUrl.searchParams.set('sref', window.location.href);
            wrappedUrl.searchParams.set('xcust', `${props.collectionItem.collectionUserId}_${props.collectionItem.collectionId}_${props.collectionItem.itemId}`);
            affiliateUrl = wrappedUrl.href;
          } else {
            affiliateUrl = productUrl;
          }
        }

      } catch(err) {
        console.error('Invalid Product URL:', productUrl);
      }
    }

  } else if (props.href) {
    try {
      const parsed = new URL(props.href);

      if (!skimlinksExclude.includes(parsed.hostname)) {
        const wrappedUrl = new URL('https://go.skimresources.com');
        wrappedUrl.searchParams.set('id', '126855X1588436');
        wrappedUrl.searchParams.set('url', props.href);
        wrappedUrl.searchParams.set('sref', window.location.href);
        affiliateUrl = wrappedUrl.href;
      } else {
        affiliateUrl = props.href;
      }
    } catch(err) {
      console.error('Invalid href:', props.href);
    }
  }

  return (
    <Link
      className={props.className}
      target={props.target ?? '_blank'}
      onClick={() => {
        if (props.collectionItem && affiliateUrl) {
          cloud.call('collections-incrementClicks')({
            user_uid: props.collectionItem.collectionUserId,
            collection_uid: props.collectionItem.collectionId,
            url: affiliateUrl,
          });
        }
        return;
      }}
      href={affiliateUrl}
    >
      {props.children}
    </Link>
  );
};
