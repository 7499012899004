import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { object, string } from 'yup';
import R from 'src/routes';
import {
  SignInFormContainerProps,
  SignInFormProps,
  SignInFormFormContent,
} from './SignInForm.types';
import { SignInForm } from './SignInForm.component';
import { useSignInProviders } from 'src/hooks/useSignInProviders/useSignInProviders';

const initialValues: SignInFormFormContent = {
  email: '',
  password: '',
};

const validationSchema = object({
  email: string()
    .email('Must be a valid email.')
    .max(254, 'Max 254 characters.')
    .required('Email is required.'),
  password: string()
    .min(6, 'Minimum 6 characters.')
    .max(128, 'Maximum 128 characters.')
    .required('Password is required.'),
}).required();

const SignInFormContainer: React.FC<SignInFormContainerProps> = () => {
  const match = useRouteMatch(R.SIGNINFROMEXTENSION);
  const isExtensionSignIn = Boolean(match?.isExact);

  const {
    handleUserEmailSignIn,
    handleFacebookAuth,
    handleGoogleAuth,
    handleAppleAuth,
    isWorking,
    mainErrors,
  } = useSignInProviders(isExtensionSignIn ? R.POSTINSTALL_ONE : R.HOME);

  const handleSubmit = useCallback<SignInFormProps['onSubmit']>(
    ({ email, password }, actions) => {
      handleUserEmailSignIn({ email, password });
      actions.setSubmitting(false);
    },
    [handleUserEmailSignIn],
  );

  return (
    <SignInForm
      signUpPath={isExtensionSignIn ? R.SIGNUPFROMEXTENSION : R.SIGNUP}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mainErrors={mainErrors}
      isWorking={isWorking}
      onSubmit={handleSubmit}
      onFacebookAuth={handleFacebookAuth}
      onGoogleAuth={handleGoogleAuth}
      onAppleAuth={handleAppleAuth}
    />
  );
};

export { SignInFormContainer as SignInForm };
